/* eslint-disable import/prefer-default-export */
import { accessToken } from '@/services/cookies';

import * as Names from '@/plugins/vue-router/routeNames';

/*
 * Обязательный guard для выполнения перед всеми переходами
 */
function hasToken() {
  const token = accessToken.get();

  if (!token) {
    accessToken.remove();

    // IMPORTANT: редиректим на нашу страницу авторизации
    // TODO: пока без ?back=${window.location.href}
    const url = `/${Names.R_APP_SIGNIN}`;
    window.location.replace(url);
  }

  return false;
}

export { hasToken };
