import { R_APP } from '@/plugins/vue-router/routeNames';

/**
 * Проверка доступа к целям
 */
export default ({ store }) => {
  if (store.getters['settings/accessToGoals']) return true;

  return { name: R_APP };
};
