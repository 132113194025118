import camelCase from 'lodash/camelCase';

const modules = {};
function staticLoadModules() {
  // eslint-disable-next-line global-require
  modules.happyState = require('./happyState').default;
  // eslint-disable-next-line global-require
  modules.levels = require('./levels').default;
  // eslint-disable-next-line global-require
  modules.settings = require('./settings').default;
}
if (process.env.NODE_ENV === 'test') {
  staticLoadModules();
} else {
  const requireModule = require.context('.', false, /\.js$/);
  requireModule.keys().forEach((f) => {
    if (f === './index.js') return;
    const moduleName = camelCase(f.replace(/(\.\/|\.js)/g, ''));
    modules[moduleName] = requireModule(f).default;
  });
}

export default modules;
