/* eslint-disable prefer-const */

function mergeContents({ oldContents, diff }) {
  return oldContents.map((oldContent) => {
    let { id, ...rest } = oldContent;
    const newContent = diff.find(({ id: newId }) => newId === id);
    if (newContent) {
      rest = newContent;

      return { id, ...oldContent, ...rest };
    }

    return oldContent;
  });
}

function mergeTasks({ oldTasks, diff }) {
  return oldTasks.map((oldTask) => {
    let { id, content, ...rest } = oldTask;
    const newTask = diff.find(({ id: newId }) => newId === id);
    if (newTask) {
      const { content: newContent, ...newRest } = newTask;
      content = mergeContents({ oldContents: content, diff: newContent });
      rest = { ...rest, ...newRest };

      return { id, content, ...rest };
    }

    return oldTask;
  });
}

function mergeLevels(oldLevels, diff) {
  return oldLevels.map((oldLevel) => {
    let { id, tasks, ...rest } = oldLevel;
    const newLevel = diff.find(({ id: newId }) => newId === id);
    if (newLevel) {
      const { tasks: newTasks, ...newRest } = newLevel;
      tasks = mergeTasks({ oldTasks: tasks, diff: newTasks });
      rest = { ...rest, ...newRest };

      return { id, tasks, ...rest };
    }

    return oldLevel;
  });
}

export default mergeLevels;
