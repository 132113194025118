/* eslint-disable import/prefer-default-export */
import accessToken from '@/services/cookies/accessToken';
import contentLanguage from '@/services/cookies/contentLanguage';
import i18n from '@/plugins/vue-i18n/index';

async function handleContentLanguage(response) {
  const contentLanguageHeader = response.headers['content-language'];
  if (!accessToken.get()) {
    contentLanguage.remove();
  }
  if (accessToken.get() && contentLanguage.get() !== contentLanguageHeader) {
    contentLanguage.set(contentLanguageHeader);
    i18n.locale = contentLanguageHeader;
  }
  return response;
}

export { handleContentLanguage };
