import { SET_ACTIVE_HAPPY_STATE, SET_HAPPY_STATES } from '@/plugins/vuex/mutationTypes';
import {
  SET_HAPPY_STATE_STATES_ACTION,
  SET_HAPPY_STATE_SHOW_ACTION,
  SHOW_HAPPY_STATE_ACTION,
} from '@/plugins/vuex/actionTypes';
import iconDrumb from '@/assets/happy_state/drumb.png';
import iconCrown from '@/assets/happy_state/crown.png';
import iconRocket from '@/assets/happy_state/rocket.png';
import iconFolder from '@/assets/happy_state/folder.png';
import iconFolderEmpty from '@/assets/happy_state/folder_empty.png';

const getTemplateString = (text) => {
  if (Array.isArray(text) && text.length && typeof text[0] === 'string') {
    const arrText = [...text];
    return arrText.shift()?.replace(/%s/g, () => arrText.shift());
  }
  if (typeof text === 'string' && text !== '') {
    return text;
  }
  return null;
};

const LOCAL_STORAGE_KEY_IDS_SHOWN_HAPPY_STATES = 'shownIdsHappyStates';
const TYPE_ICONS = {
  task_complete: iconDrumb,
  adaptation_complete: iconRocket,
  adaptation_complete_badge: iconCrown,
  purpose_complete: iconFolder,
  purpose_complete_failed: iconFolderEmpty,
};
const getTypeData = (happyState) => {
  let data = {
    show: true,
    id: happyState?.id,
    iconUrl: happyState?.iconUrl,
    iconType: TYPE_ICONS[happyState?.type],
    title: getTemplateString(happyState?.bodyHeader),
    message: getTemplateString(happyState?.bodyMessage),
    description: getTemplateString(happyState?.description),
    buttonText: getTemplateString(happyState?.buttonText),
    type: happyState?.type,
  };
  if (data.type === 'level_complete') {
    data = {
      ...data,
      title: getTemplateString(happyState?.ribbon),
      description: getTemplateString(happyState?.bodyHeader),
    };
  } else if (data.type === 'badge') {
    data = {
      ...data,
      description: getTemplateString(happyState?.ribbon),
    };
  } else if (data.type === 'purpose_complete' && data.title.toLowerCase().indexOf('поздравляем') === -1) {
    // TODO: пока проверка, что цель не завершена проверяется по вхождению строки, впоследсвии
    // нужно на бэке поменять тип на `purpose_complete_failed`
    data = {
      ...data,
      iconType: TYPE_ICONS.purpose_complete_failed,
    };
  }
  return data;
};
const happyStateModule = {
  namespaced: true,
  state: {
    happyStates: [],
    activeHappyState: {
      show: false,
      id: null,
      iconUrl: '',
      iconType: '',
      title: '',
      message: '',
      description: '',
      buttonText: '',
      type: '',
    },
  },

  mutations: {
    [SET_HAPPY_STATES](state, payload) {
      state.happyStates = payload;
    },
    [SET_ACTIVE_HAPPY_STATE](state, payload) {
      Object.assign(state.activeHappyState, payload);
    },
  },

  actions: {
    [SHOW_HAPPY_STATE_ACTION]({ commit, getters }) {
      let idsShown = [];
      try {
        idsShown = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_IDS_SHOWN_HAPPY_STATES));
        idsShown = Array.isArray(idsShown) ? idsShown : [];
      } catch (e) {
        // TODO обработчик на ошибку
        console.warn(e);
      }
      getters.happyStates?.every((happyState) => {
        const isShown = getters.activeHappyState?.id === happyState.id || idsShown?.some((id) => id === happyState.id);
        if (!isShown) {
          commit(SET_ACTIVE_HAPPY_STATE, getTypeData(happyState));
          idsShown.push(happyState.id);
        }
        return isShown;
      });
      localStorage.setItem(LOCAL_STORAGE_KEY_IDS_SHOWN_HAPPY_STATES, JSON.stringify(idsShown));
    },
    [SET_HAPPY_STATE_STATES_ACTION]({ commit, dispatch }, { states }) {
      commit(SET_HAPPY_STATES, states);
      dispatch(SHOW_HAPPY_STATE_ACTION);
    },
    [SET_HAPPY_STATE_SHOW_ACTION]({ commit, dispatch }, payload) {
      commit(SET_ACTIVE_HAPPY_STATE, { show: payload });
      // Показываем хеппи стейт который еще не был просмотрен
      dispatch(SHOW_HAPPY_STATE_ACTION);
    },
  },

  getters: {
    activeHappyState: (state) => state.activeHappyState,
    happyStates: (state) => state.happyStates,
  },
};

export default happyStateModule;
