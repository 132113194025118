import Router from 'vue-router';

function createRouter({ routes, baseUrl, ...options }) {
  const router = new Router({
    mode: 'history',
    baseUrl,
    // Note: отображается во вкладке маршрутов в devtools@^5.0.1
    routes,
    ...options,
  });

  return router;
}

export default createRouter;
