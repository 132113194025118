import { SET_SETTINGS } from '@/plugins/vuex/mutationTypes';
import { SET_SETTINGS_ACTION } from '@/plugins/vuex/actionTypes';

const settingsModule = {
  namespaced: true,
  state() {
    return {
      bot: {},
      phrase: {},
      options: {},
      isTutor: false,
      hideRating: false,
    };
  },
  actions: {
    [SET_SETTINGS_ACTION]({ commit }, payload) {
      commit(SET_SETTINGS, payload);
    },
  },

  mutations: {
    [SET_SETTINGS](state, payload) {
      const {
        config: {
          botIconUrl, botName, options, phrase, hideRating,
        },
        staff: { isTutor },
      } = payload;
      state.bot = { botIconUrl, botName };
      state.phrase = phrase;
      state.options = options;
      state.isTutor = isTutor;
      state.hideRating = hideRating;
    },
  },

  getters: {
    bot: (state) => state.bot,
    options: (state) => state.options,
    accessToGoals: (state) => state.options?.showPurposes && !state.isTutor,
    hideRating: (state) => !!state?.hideRating,
  },
};

export default settingsModule;
