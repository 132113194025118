/*
 * гвард должен возвращать true
 * guard для проверки роли пользователя в качестве наставника
 */

import { R_APP } from '@/plugins/vue-router/routeNames';

const isTutorMiddleware = ({ store }) => {
  const { isTutor } = store.state.settings;
  if (isTutor) return true;

  return { name: R_APP };
};

export default isTutorMiddleware;
