import { featuresGet } from '@/services/api/features';

const GLFeaturePlugin = async () => {
  const { features } = await featuresGet();
  return {
    install(Vue) {
      Vue.mixin({
        provide: {
          glFeatures(flagName) {
            const featuresPool = features || [];
            return featuresPool.find((item) => item.name === flagName)?.enabled || false;
          },
        },
      });
    },
  };
};

export default GLFeaturePlugin();
