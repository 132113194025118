export const SET_SETTINGS_ACTION = 'setSettingsAction';
export const SET_HAPPY_STATE_STATES_ACTION = 'setHappyStateStatesAction';
export const SET_HAPPY_STATE_SHOW_ACTION = 'setHappyStateShow';
export const SHOW_HAPPY_STATE_ACTION = 'showHappyState';

export const INIT_APPLICATION_ACTION = 'initApplicationAction';
export const SET_LEVEL_LIST_ACTION = 'setLevelListAction';

export const SKIP_ITEM_ACTION = 'skipItemAction';

export const GET_EVENTS_ACTION = 'getEventsAction';
export const GET_HANDBOOK_ACTION = 'getHandbookAction';
export const GET_PURPOSE_ACTION = 'getPurposeAction';

export const GET_GOALS_ACTION = 'getGoalsAction';
export const GET_TRAINEE_GOALS_ACTION = 'getTraineeGoalsAction';

export const GET_STAFF_ACTION = 'getStaffActions';
