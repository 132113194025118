export const HTML = 'HTML';
export const ENTER_STRING = 'ENTER_STRING';
export const ADD_PHOTO = 'ADD_PHOTO';
export const RATE = 'RATE';
export const TASK = 'TASK';
export const CLOSED = 'CLOSED';
export const OPENED = 'OPENED';
export const OPENED_NEXT = 'OPENED_NEXT';

export const COMPLETED = 'COMPLETED';
export const REQUIRED = 'REQUIRED';
export const SKIPPED = 'SKIPPED';
export const NEW = 'NEW';
export const FAILED = 'FAILED';
export const REJECTED = 'REJECTED';
export const UNKNOWN = 'UNKNOWN';

export const RATE_TYPE_SMILE = 'smile';

export const VIDEO = 'VIDEO';
export const DOCUMENT = 'DOCUMENT';
export const YES_NO = 'YES_NO';
export const CONTACT = 'CONTACT';
export const MAP = 'MAP';
export const SELECT_ONE = 'SELECT_ONE';
export const SELECT_MANY = 'SELECT_MANY';
export const ONE_WORD = 'ONE_WORD';
export const QR_CODE = 'QR_CODE';
export const IMAGE = 'IMAGE';
export const VOTE = 'VOTE';
export const OFFER = 'OFFER';

export const PURPOSE_STATES = {
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED',
  REJECTED: 'REJECTED',
  NEW: 'NEW',
  UNKNOWN: 'UNKNOWN',
};

export const LEVEL_STATE = {
  CLOSED,
  OPENED,
  COMPLETED,
  OPENED_NEXT,
};

export const ALLOWED_CHAT_TYPE = {
  HTML,
  ENTER_STRING,
  ADD_PHOTO,
  RATE,
};

export const ITEM_TYPES = {
  HTML,
  ENTER_STRING,
  ADD_PHOTO,
  RATE,
  VIDEO,
  DOCUMENT,
  YES_NO,
  CONTACT,
  MAP,
  SELECT_ONE,
  SELECT_MANY,
  VOTE,
  ONE_WORD,
  QR_CODE,
  IMAGE,
  OFFER,
};

export const GOAL_TYPES = {
  personal: 'personal',
  trainee: 'trainee',
};

export const itemStatuses = {
  COMPLETED,
  REQUIRED,
  SKIPPED,
};

export const taskType = {
  WATCH: 'WATCH',
  READ: 'READ',
  TAKE: 'TAKE',
  LOCATION: 'LOCATION',
  TEST: 'TEST',
  TASK: 'TASK',
  MEET: 'MEET',
  OTHER: 'OTHER',
  QRCODE: 'QRCODE',
  POOL: 'POOL',
  BUG: 'BUG',
  CHECKPOINT: 'CHECKPOINT',
};

export const V_ENTER_ANSWER = 'validators.enter_answer';
export const V_ANSWER_LEN = 'validators.answer_len';
export const V_LESS = 'validators.less';
export const V_CHAR = 'validators.char';
export const V_MORE = 'validators.more';
export const V_PIXEL = 'validators.pixel';
export const V_WIDTH = 'validators.width';
export const V_HEIGHT = 'validators.height';
export const V_IMAGE = 'validators.image';

// eslint-disable-next-line max-len, vue/max-len,no-control-regex
export const EMAIL_REGEX = /^(?:[A-zА-яёЁ0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-zА-яёЁ0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zа-я0-9](?:[a-zа-я0-9-]*[a-zа-я0-9])?\.)+[a-zа-я0-9]{2,}(?:[a-zа-я0-9-]*[a-zа-я0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zа-я0-9-]*[a-zа-я0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

export const entityType = {
  TASK: 'TASK',
  EVENT: 'EVENT',
  NOT_SET: 'NOT_SET',
};
export const chipStatusTypes = {
  ITEM: 'ITEM',
  EVENT: 'EVENT',
  PURPOSE: 'PURPOSE',
};
export const TRAINEES_TASK_STATUSES = {
  PENDING: 'PENDING',
  WITH_PROBLEM: 'WITH_PROBLEM',
  WITHOUT_PROBLEM: 'WITHOUT_PROBLEM',
  FAILED: 'FAILED',
};

export const APP_ASIDE_RIGHT_WIDTH = 326;
export const TRACK_LEVEL_CARD_IMAGE_SIZE = 96;
export const LEVEL_CARD_IMAGE_SIZE = 64;
export const GOAL_TITLE_MAX_LENGTH = 60;

export const PHONE_CODE_LENGTH = 4;
export const LOGIN_STEPS = {
  phone: 'phone',
  code: 'code',
};
