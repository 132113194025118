import {
  MERGE_LEVELS, SKIP_ITEM, SET_LEVEL_LIST, SET_ITEM_SKIPPED,
} from '@/plugins/vuex/mutationTypes';
import mergeLevels from '@/helpers/mergeLevels';
import { COMPLETED } from '@/helpers/constants';
import { SET_LEVEL_LIST_ACTION, SKIP_ITEM_ACTION } from '@/plugins/vuex/actionTypes';

const levelsModule = {
  namespaced: true,

  state() {
    return {
      levelList: [],
      skippedTaskItem: (() => {
        try {
          const result = JSON.parse(localStorage.getItem('skippedTaskItem'));
          if (result.length) { return result; }
          return [];
        } catch { return []; }
      })(),
    };
  },

  actions: {
    [SET_LEVEL_LIST_ACTION]({ commit }, levelList) {
      commit(SET_LEVEL_LIST, levelList);
    },

    [SKIP_ITEM_ACTION]({ commit }, payload) {
      commit(SKIP_ITEM, payload);
      commit(SET_ITEM_SKIPPED, payload);
    },
  },

  mutations: {
    [SET_LEVEL_LIST](state, payload) {
      state.levelList = payload;
    },

    [SKIP_ITEM](state, itemId) {
      state.skippedTaskItem.push(itemId);
      localStorage.setItem('skippedTaskItem', JSON.stringify([...new Set(state.skippedTaskItem)]));
    },
    // eslint-disable-next-line no-unused-vars
    [SET_ITEM_SKIPPED](state, itemId) {
      // eslint-disable-next-line no-unused-vars
      const chatLevel = state.levelList.find((lvl) => lvl.levelN === 0);
      if (chatLevel) {
        chatLevel.tasks
          .forEach((task) => task.content
            .forEach((content) => {
              if (content.id === itemId) {
                // eslint-disable-next-line no-param-reassign
                content.isSkipped = true;
              }
            }));
      }
    },

    [MERGE_LEVELS](state, payload) {
      state.levelList = mergeLevels(state.levelList, payload);
    },
  },

  getters: {
    levelList: (state) => state.levelList,
    levelById: (state) => (id) => state.levelList.find((lvl) => lvl.id === id),
    chat: (state) => state.levelList.find((lvl) => lvl.levelN === 0),
    isChatCompleted: (state, getters) => getters.chat?.state === COMPLETED,
    onboardingTrack: (state) => state.levelList.filter((lvl) => lvl.levelN !== 0),
    skippedTaskItem: (state) => state.skippedTaskItem,
  },
};

export default levelsModule;
