/*
 * гвард должен возвращать false
 * guard для проверки доступа юзера к следующим разделам приложения после чата
 */
import { R_APP_CHAT, R_APP } from '@/plugins/vue-router/routeNames';

const isChatCompleted = ({ to, store }) => {
  if (store.getters['levels/isChatCompleted']) {
    if (to.name === R_APP_CHAT) {
      return { name: R_APP };
    }
    return false;
  }
  return to.name === R_APP_CHAT ? false : { name: R_APP_CHAT };
};

export default isChatCompleted;
