/* eslint-disable import/prefer-default-export */
import { create } from 'axios';
import toSnakeCaseRequestTransformer from '@/services/api/_utils/transformers/toSnakeCaseRequestTransformer';
import toCamelCaseResponseTransformer from '@/services/api/_utils/transformers/toCamelCaseResponseTransformer';
import authorization from '@/services/api/_utils/interceptors/authorization';
import companyId from '@/services/api/_utils/interceptors/companyId';
import { onError } from '@/services/api/_utils/interceptors/onError';
import { chooseLanguage } from '@/helpers/language';
import { handleContentLanguage } from '@/services/api/_utils/interceptors/handleContentLanguage';
import { contentLanguage } from '@/services/cookies';

/*
 * @see: https://github.com/axios/axios#request-config
 */
function createAxiosTransport({ baseURL = 'http://localhost:3000', ...options } = {}) {
  const transport = create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      'Accept-language': chooseLanguage(
        {
          navigatorLang: navigator.language,
          serverSideLanguage: contentLanguage.get(),
        },
      ),
    },
    responseType: 'json',
    responseEncoding: 'utf8',
    ...options,
  });

  // IMPORTANT: стандартный трансформер запросов конвертит данные в строки,
  // поэтому сперва используй наш трансформер запросов для массивов и объектов
  transport.defaults.transformRequest.unshift(toSnakeCaseRequestTransformer);
  transport.defaults.transformResponse.push(toCamelCaseResponseTransformer);

  // IMPORTANT: порядок для колбеков ОБРАТНЫЙ !!!
  transport.interceptors.request.use(authorization);
  transport.interceptors.request.use(companyId);

  // HACK: у axios есть баг, интерсепторы запросов идут в обратном порядке, в
  // отличии от интерсепторов ответов, поэтому мутируем порядок с помощью
  // reverseInterceptorsOrder() или reverse()
  // see:
  //  * https://github.com/axios/axios/issues/1663
  //  * https://github.com/axios/axios/pull/2085
  if (typeof transport.reverseInterceptorsOrder === 'function') {
    transport.reverseInterceptorsOrder();
  } else {
    transport.interceptors.request.handlers.reverse();
  }

  // IMPORTANT: порядок для коллбеков ПРЯМОЙ !!!
  transport.interceptors.response.use(undefined, (error) => onError(error, transport));
  transport.interceptors.response.use((response) => handleContentLanguage(response), undefined);

  return transport;
}

export { createAxiosTransport };
