import { GET_GOALS_ACTION, GET_TRAINEE_GOALS_ACTION } from '@/plugins/vuex/actionTypes';
import { SET_GOALS, SET_GOALS_LOADIND, SET_USER_GOALS } from '@/plugins/vuex/mutationTypes';
import { purposeListGet, tutorStaffListGet } from '@/services/api/snami';

const goalModule = {
  namespaced: true,
  state() {
    return {
      loading: false,
      goals: [],
      goalsByUserId: {},
    };
  },
  actions: {
    async [GET_GOALS_ACTION]({ commit, getters }) {
      if (!getters.loading) {
        commit(SET_GOALS_LOADIND, true);
        const { data } = await purposeListGet();
        commit(SET_GOALS, data);
        commit(SET_GOALS_LOADIND, false);
      }
    },
    async [GET_TRAINEE_GOALS_ACTION]({ commit, getters }, { userId }) {
      if (!getters.loading) {
        commit(SET_GOALS_LOADIND, true);
        const { data } = await tutorStaffListGet();
        const user = data.find((item) => item.id === +userId);
        commit(SET_USER_GOALS, { goals: user.purposes, userId });
        commit(SET_GOALS_LOADIND, false);
      }
    },
  },
  mutations: {
    [SET_GOALS](state, payload) {
      state.goals = payload;
    },
    [SET_USER_GOALS](state, { goals, userId }) {
      state.goalsByUserId = { ...state.goalsByUserId, [+userId]: goals };
    },
    [SET_GOALS_LOADIND](state, payload) {
      state.loading = payload;
    },

  },
  getters: {
    loading: (state) => state.loading,
    goals: (state) => state.goals,
    goalsByUserId: (state) => (userId) => state.goalsByUserId[+userId] || [],
  },
};

export default goalModule;
