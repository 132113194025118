/* eslint-disable import/prefer-default-export */
import accessToken from '@/services/cookies/accessToken';
// import companyId from '@/services/cookies/companyId';
// import { getDomainForCookies } from '@/helpers/url';
import * as Names from '@/plugins/vue-router/routeNames';

// const { VUE_APP_ARCHIVIST_ADDRESS, VUE_APP_GATEKEEPER_ADDRESS } = process.env;
const HTTP_FORBIDDEN = 403;
const HTTP_UNAUTHORIZED = 401;
const PATHNAME_SIGNIN = `/${Names.R_APP_SIGNIN}`;
const redirectTo = (targetUrl) => window.location.replace(targetUrl);
const equalPathnameSignin = () => window.location.pathname === PATHNAME_SIGNIN;
const flushAuth = () => {
  accessToken.remove(); // { domain: getDomainForCookies() }
};
const redirectToSignin = () => redirectTo(PATHNAME_SIGNIN);
/*
 * Обязательный interceptor для обработки глобальных и локальный ошибок
 */
async function onError(error, transport) {
  const res = error.response;
  const status = res?.status;
  if (!equalPathnameSignin() && [HTTP_UNAUTHORIZED, HTTP_FORBIDDEN].includes(status)) {
    // eslint-disable-next-line no-underscore-dangle
    if (HTTP_UNAUTHORIZED === status && accessToken.get() && error.config && !error.config.__isRetryRequest) {
      try {
        const resResponse = await transport({
          url: '/v2/client/refresh',
          method: 'POST',
          // Т.к используется тот-же транспорт, что и основной, то сразу указываем,
          // что повторных запросов делать не надо в случае ошибки
          __isRetryRequest: true,
        });
        accessToken.set(resResponse.data?.token?.token, {
          expires: 365,
        });
      } catch (authError) {
        flushAuth();
        redirectToSignin();
        return Promise.reject(error);
      }
      // eslint-disable-next-line no-param-reassign,no-underscore-dangle
      error.config.__isRetryRequest = true;
      return transport(error.config);
    }
    flushAuth();
    redirectToSignin();
  }

  return Promise.reject(error);
  /*
  * @see: https://burning-heart.atlassian.net/wiki/spaces/FND/pages/1983938561
  * Логика обработки ошибок с Платформы
  const HTTP_FORBIDDEN = 403;
  const HTTP_UNAUTHORIZED = 401;
  const currentModuleUrl = new URL(window.location.href);
  const archivistModuleUrl = new URL(VUE_APP_ARCHIVIST_ADDRESS);
  const gatekeeperModuleUrl = new URL(VUE_APP_GATEKEEPER_ADDRESS);
  const flushAuth = () => {
    accessToken.remove({ domain: getDomainForCookies() });
    companyId.remove({ domain: getDomainForCookies() });
  };
  // HACK: после того как fatal и нормальный json ответ приедет во все сервисы убрать fallback
  // TODO: расширить кейсом формата ответа бэкенда адаптации
  // IMPORTANT: исключение для Адаптации в случае если бэк не возвращает fatal = true
  const fallback = !error.response?.data
    || (error.response?.data?.error && error.response?.data?.error?.fatal !== false);
  // HACK: 401 интерпретируем как 403 для Адапатации
  const isGlobalError = error.isAxiosError === true
    && [HTTP_FORBIDDEN, HTTP_UNAUTHORIZED].includes(error.response?.status)
    && (fallback || Boolean(error.response?.data?.error?.fatal) === true);
  const isArchivistModule = currentModuleUrl.origin === archivistModuleUrl.origin;
  const hasRetry = Boolean(currentModuleUrl.searchParams.get('retry')) === true;
  // TODO: whitelist для урлов и back урлов
  const redirectTo = (targetUrl) => window.location.replace(targetUrl);

  if (isGlobalError) {
    if (isArchivistModule) {
      // Глобальная ошибка в профиле
      flushAuth();

      redirectTo(gatekeeperModuleUrl.href);
    } else if (hasRetry) {
      // Глобальная ошибка в модуле и есть флаг retry
      redirectTo(archivistModuleUrl.href);
    } else {
      // Глобальная ошибка в модуле и нет флага retry
      flushAuth();

      const backUrl = new URL('/?retry=true', currentModuleUrl);

      const url = new URL(`/?back=${backUrl.href}`, gatekeeperModuleUrl);

      redirectTo(url.href);
    }
  }
  */
}

export { onError };
