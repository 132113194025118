/* eslint-disable import/prefer-default-export */
import * as methods from '@/services/api/_utils/constants';
import transport from './transport';

/**
 * Гайд по именованию ресурсов
 *
 * @see: https://burning-heart.atlassian.net/wiki/spaces/front/pages/1375109156/JavaScript
 */

export const loginRequest = (phone, preferTransport = 'sms') => transport({
  url: '/v2/client/login/request',
  method: methods.HTTP_POST,
  data: {
    phone,
    preferTransport,
  },
});

export const loginByCode = (key, code) => transport({
  url: `/v2/client/login/${key}`,
  method: methods.HTTP_POST,
  data: {
    code,
  },
});

export const acceptAgreement = () => transport({
  url: '/staff/accept',
  method: methods.HTTP_GET,
});

export const configGet = () => transport({
  url: '/staff/config',
  method: methods.HTTP_GET,
});

export const startConfigGet = ({ data = {}, ...options } = {}) => transport({
  url: '/staff/on-start',
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const staffStatGet = ({ rateCount }) => transport({
  url: '/staff/stat',
  method: methods.HTTP_POST,
  data: {
    rateCount: rateCount || 10,
  },
});

export const staffStatByIdGet = ({ id, rateCount }) => transport({
  url: `/staff/${id}/stat`,
  method: methods.HTTP_POST,
  data: {
    id,
    rateCount,
  },
});

export const staffUpdate = ({
  about, photoBase64, updateFields,
}) => transport({
  url: '/staff/update',
  method: methods.HTTP_POST,
  data: {
    about: about || null,
    photoBase64: photoBase64 || null,
    updateFields: updateFields || null,
  },
});

export const staffEmailUpdate = (email) => transport({
  url: '/staff/email/update',
  method: methods.HTTP_POST,
  data: {
    email,
  },
});

export const staffEmailConfirmKey = (key) => transport({
  url: `/staff/email/confirm/${key}`,
  method: methods.HTTP_POST,
  data: {
    key,
  },
});

export const levelListV2Get = ({ data = {}, ...options } = {}) => transport({
  url: 'staff/all/list/v2',
  method: methods.HTTP_GET,
  data,
  ...options,
});

export const setShown = ({ data = {}, ...options } = {}) => transport({
  url: 'staff/task/shown',
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const taskResultUpdate = ({ data = {}, ...options } = {}) => transport({
  url: '/staff/task-result/update/v2',
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const taskComplete = ({ data = {}, ...options } = {}) => transport({
  url: '/staff/task/complete',
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const appealCreate = ({ data = {}, ...options } = {}) => transport({
  url: '/staff/appeal/create',
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const achievementsGet = ({ data = {}, ...options } = {}) => transport({
  url: '/customer/achievement/list',
  method: methods.HTTP_POST,
  data,
  ...options,
});

/**
 *
 * @param data
 * @param options
 */
export const profileGet = ({ data = {}, ...options } = {}) => transport({
  url: '/staff/get/v2',
  method: methods.HTTP_GET,
  data,
  ...options,
});

export const taskSkip = ({ data = {}, ...options } = {}) => transport({
  url: '/staff/task/skip',
  method: methods.HTTP_POST,
  data,
  ...options,
});
/**
 *
 * @param data
 * @param options
 */
export const profileHandbookGet = ({ data = {}, ...options } = {}) => transport({
  url: '/staff/docs/list',
  method: methods.HTTP_GET,
  data,
  ...options,
});

export const profileEventsGet = ({ data = {}, ...options } = {}) => transport({
  url: '/staff/event/list/v2',
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const purposeCreate = ({ data = {}, ...options } = {}) => transport({
  url: 'staff/purpose/create',
  method: methods.HTTP_POST,
  data,
  ...options,
});
export const purposeUpdate = ({ data = {}, ...options } = {}) => transport({
  url: 'staff/purpose/update',
  method: methods.HTTP_POST,
  data,
  ...options,
});
export const statePurposeUpdate = ({ data = {}, ...options } = {}) => transport({
  url: '/staff/purpose/update/state',
  method: methods.HTTP_POST,
  data,
  ...options,
});
export const purposeListGet = ({ data = {}, ...options } = {}) => transport({
  url: 'staff/purpose/list',
  method: methods.HTTP_POST,
  data,
  ...options,
});

export const tutorStaffListGet = ({ data = {}, ...options } = {}) => transport({
  url: 'tutor/staff/list',
  method: methods.HTTP_GET,
  data,
  ...options,
});

export const tutorCheckpointCompletePost = ({ data = {}, ...options } = {}) => transport({
  url: 'tutor/checkpoint/complete',
  method: methods.HTTP_POST,
  data,
  ...options,
});
