import { SET_HANDBOOK, SET_HANDBOOK_EVENTS, SET_PURPOSE_LIST } from '@/plugins/vuex/mutationTypes';
import { GET_EVENTS_ACTION, GET_HANDBOOK_ACTION, GET_PURPOSE_ACTION } from '@/plugins/vuex/actionTypes';
import * as snamiApi from '@/services/api/snami';
import { ITEM_TYPES, PURPOSE_STATES } from '@/helpers/constants';
import { parseMeta } from '@/helpers/generateFlatItem';

const handbookModule = {
  namespaced: true,
  state() {
    return {
      events: [],
      files: [],
      contacts: [],
      videos: [],
      purposes: [],
    };
  },
  actions: {
    async [GET_EVENTS_ACTION]({ commit }, payload) {
      const { data } = await snamiApi.profileEventsGet(payload);
      commit(SET_HANDBOOK_EVENTS, data);
    },
    async [GET_HANDBOOK_ACTION]({ commit }, payload) {
      const { data } = await snamiApi.profileHandbookGet(payload);
      commit(SET_HANDBOOK, data);
    },
    async [GET_PURPOSE_ACTION]({ commit }, payload) {
      const { data } = await snamiApi.purposeListGet(payload);
      commit(SET_PURPOSE_LIST, data);
    },
  },

  mutations: {
    [SET_HANDBOOK_EVENTS](state, payload) {
      state.events = payload;
    },
    [SET_HANDBOOK](state, payload) {
      const jsonParsedData = payload.map((item) => {
        if ('meta' in item) {
          return {
            ...item,
            meta: parseMeta(item.meta),
          };
        }
        return item;
      });
      state.files = jsonParsedData.filter((item) => item.type === ITEM_TYPES.DOCUMENT);
      state.contacts = jsonParsedData.filter((item) => item.type === ITEM_TYPES.CONTACT);
      state.videos = jsonParsedData.filter((item) => item.type === ITEM_TYPES.VIDEO);
    },
    [SET_PURPOSE_LIST](state, payload) {
      state.purposes = payload;
    },
  },

  getters: {
    hasUnreadEvents: (state) => !state.events
      .every((event) => event.task.content
        .every((taskContent) => taskContent.firstShown)),
    unreadedEvents: (state) => state.events
      .filter((event) => event.task.content
        .some((taskContent) => !taskContent.firstShown)),
    actualGoals: (state) => state.purposes.filter((item) => item.state === PURPOSE_STATES.NEW),
  },
};

export default handbookModule;
