import { Store } from 'vuex';
import {
  SET_LEVEL_LIST,
  SET_SETTINGS,
  toggleLoading,
  toggleNavDrawer,
  toggleSnack,
} from '@/plugins/vuex/mutationTypes';
import modules from '@/plugins/vuex/modules';
import plugins from '@/plugins/vuex/plugins';
import { INIT_APPLICATION_ACTION, GET_STAFF_ACTION } from '@/plugins/vuex/actionTypes';

const strict = process.env.NODE_ENV === 'development';

function createStore() {
  return new Store({
    strict,
    plugins,
    modules,
    namespaced: true,
    // TODO: refactor use modules
    state: {
      loading: false,
      navDrawer: false,
      snackbar: {
        value: false,
        message: '',
        status: '',
        timeout: 3000,
      },
    },

    actions: {
      [INIT_APPLICATION_ACTION]({ commit, dispatch }, payload) {
        commit(`settings/${SET_SETTINGS}`, payload.settings, { root: true });
        commit(`levels/${SET_LEVEL_LIST}`, payload.levels, { root: true });

        dispatch(`user/${GET_STAFF_ACTION}`);
      },
    },

    mutations: {
      [toggleLoading](state, payload) {
        state.loading = payload;
      },

      [toggleNavDrawer](state, payload) {
        state.navDrawer = payload;
      },

      [toggleSnack](state, payload) {
        const { snackbar } = state;
        snackbar.value = !snackbar.value;
        snackbar.message = payload.message || '';
        snackbar.status = payload.status || '';
      },
    },

    getters: {},
  });
}

export default createStore;
