<template>
  <div
    class="tt-textarea"
    :class="classesList"
    :data-test="dataTest"
    :data-test-label="dataTestLabel"
    :data-test-value="dataTestValue"
  >
    <div
      v-if="!!label || !!counter"
      :class="['label d-flex w-100',
               {'justify-space-between' : !!label && !!counter },
               {'justify-end' : !label && !!counter}
      ] "
    >
      <label
        v-if="!!label"
        :class="{ 'tt-light-mono-24--text' : disabled }"
      >
        {{ label }}
      </label>
      <span
        v-if="!!counter"
        class="tt-text-body-2 tt-light-mono-100--text"
        :class="{ 'tt-light-mono-24--text' : disabled }"
      >{{ value.length }}{{ ` / ${maxlength}` }}</span>
    </div>
    <VTextarea
      :hide-details="hideDetails"
      :color="color"
      :value="value"
      :label="''"
      :disabled="disabled"
      :rows="rows"
      :row-height="24"
      v-bind="{...$attrs, ...$props}"
      :data-test="`${dataTest}--input`"
      :data-test-label="dataTestLabel"
      :data-test-value="dataTestValue"
      v-on="$listeners"
    >
      <template #append>
        <slot name="append" />
      </template>

      <template #append-outer>
        <slot name="append-outer" />
      </template>

      <template #default>
        <slot name="default" />
      </template>

      <template
        v-if="$scopedSlots.message"
        #message="props"
      >
        <slot
          name="message"
          v-bind="props"
        />
      </template>

      <template #prepend-inner>
        <slot name="prepend-inner" />
      </template>

      <template #progress>
        <slot name="progress" />
      </template>
    </VTextarea>
  </div>
</template>

<script>
export default {
  name: 'TTTextarea',
  props: {
    color: {
      type: String,
      default: 'tt-light-mono-16',
    },
    counter: {
      type: [Boolean, Number, String],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: [String, Boolean],
      default: 'auto',
    },
    noResize: {
      type: Boolean,
      default: true,
    },
    rows: {
      type: Number,
      default: 1,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },

    // custom props
    dataTest: {
      type: String,
      default: 'tt-textarea',
    },
    dataTestLabel: {
      type: String,
      default: '',
    },
    dataTestValue: {
      type: String,
      default: '',
    },
    large: Boolean,
    small: Boolean,
    xSmall: Boolean,
    maxlength: {
      type: [Boolean, Number, String],
      default: 250,
    },
  },
  computed: {
    classesList() {
      return {
        'v-size--large': this.large,
        'v-size--small': this.small,
        'v-size--x-small': this.xSmall,
        'v-size--default': !this.xLarge && !this.large && !this.xSmall && !this.small,
      };
    },
  },
};
</script>

<style lang="scss">
@import "TTTextarea";
</style>
