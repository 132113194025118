import { itemStatuses } from '@/helpers/constants';

export const parseMeta = (meta) => {
  if (meta) {
    try {
      if (typeof meta === 'string') {
        return JSON.parse(meta);
      }
      if (typeof meta === 'object') {
        return meta;
      }
    } catch (e) {
      // no-error
    }
  }
  return {};
};
export const makeItemStatus = ({
  isSkipped = false,
  isCompleted = false,
  isRequired = false,
}) => {
  switch (true) {
    case isCompleted: return itemStatuses.COMPLETED;
    case isSkipped: return itemStatuses.SKIPPED;
    case isRequired: return itemStatuses.REQUIRED;
    default: return null;
  }
};

export const generateFlatItem = ({
  // eslint-disable-next-line no-unused-vars
  skippedArray, levelId, taskId, taskCompleted, taskItem, isChat = false,
}) => {
  const {
    // eslint-disable-next-line no-unused-vars
    id, firstShown, canHasResult, canBeSkipped, result, meta: itemMeta, isSkipped: isItemSkipped,
  } = taskItem;
  const shown = !!firstShown;
  const isSkipped = canBeSkipped && (isItemSkipped || skippedArray.includes(id));
  const isCompleted = canHasResult && result && !isSkipped;
  const isRequired = canHasResult && !canBeSkipped && !result;
  const isTaskCompleted = taskCompleted;
  const itemStatus = makeItemStatus({
    isSkipped,
    isCompleted,
    isRequired,
  });
  const meta = parseMeta(itemMeta);
  const flatItem = {
    ...taskItem, taskId, levelId, shown, isSkipped, meta, isCompleted, isTaskCompleted, itemStatus,
  };
  if (isChat) {
    return {
      ...flatItem,
      faked: false,
      isUser: false,
    };
  }
  return flatItem;
};
