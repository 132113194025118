<template>
  <div
    :class="['tt-data-table', classList]"
    :data-test="dataTest"
    :data-test-label="dataTestLabel"
    :data-test-value="dataTestValue"
  >
    <VDataTable
      :hide-default-footer="hideDefaultFooter"
      :hide-default-header="hideDefaultHeader"
      :show-expand="showExpand"
      :items="items"
      :headers="headers"
      :expanded="expanded"
      :disable-pagination="disablePagination"
      expand-icon="$dropdown"
      v-bind="$props"
      :data-test="dataTest"
      :data-test-label="dataTestLabel"
      :data-test-value="dataTestValue"
      v-on="$listeners"
    >
      <template #header="props">
        <slot
          name="header"
          v-bind="props"
        />
      </template>
      <template
        v-for="h in headers"
        v-slot:[`header.${h.value}`]="props"
      >
        <slot
          :name="`header.${h.value}`"
          v-bind="props"
        >
          {{ h.text }}
        </slot>
      </template>
      <template
        v-if="hasItemSlot"
        #item="item"
      >
        <slot
          name="item"
          v-bind="item"
        >
          <tr>
            <td>{{ item }}</td>
          </tr>
        </slot>
      </template>
      <template
        v-for="h in headers"
        v-slot:[`item.${h.value}`]="props"
      >
        <slot
          :name="`item.${h.value}`"
          v-bind="props"
        >
          {{ props.value }}
        </slot>
      </template>
      <template
        v-if="showExpand"
        v-slot:expanded-item="props"
      >
        <slot
          name="expanded-item"
          v-bind="props"
        />
      </template>
    </VDataTable>
  </div>
</template>

<script>

export default {
  name: 'TTDataTable',
  props: {
    dataTest: {
      type: String,
      default: 'tt-data-table',
    },
    dataTestLabel: {
      type: String,
      default: '',
    },
    dataTestValue: {
      type: String,
      default: '',
    },
    calculateWidths: Boolean,
    caption: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/require-default-prop
    customFilter: Function,
    // eslint-disable-next-line vue/require-default-prop
    customSort: Function,
    dark: Boolean,
    dense: Boolean,
    disableFiltering: Boolean,
    disablePagination: Boolean,
    disableSort: Boolean,
    expanded: {
      type: Array,
      default: () => [],
    },
    expandIcon: {
      type: String,
      default: '$expand',
    },
    extraTall: Boolean,
    fixedHeader: Boolean,
    footerProps: {
      type: [Object],
      default: () => {
      },
    },
    groupBy: {
      type: [String, Array],
      default: () => [],
    },
    groupDesc: {
      type: [Boolean, Array],
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    headerProps: {
      type: [Object],
      default: () => {
      },
    },
    headersLength: {
      type: [Number],
      default: 0,
    },
    hideDefaultFooter: {
      type: Boolean,
      default: true,
    },
    hideDefaultHeader: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemClass: {
      type: [String, Function],
      default: () => '',
    },
    itemKey: {
      type: String,
      default: 'id',
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    loaderHeight: {
      type: [Number, String],
      default: 4,
    },
    loading: {
      type: [String, Boolean],
      default: false,
    },
    loadingText: {
      type: String,
      default: '$vuetify.dataIterator.loadingText',
    },
    locale: {
      type: String,
      default: 'ru-RU',
    },
    tall: Boolean,
    showExpand: {
      type: Boolean,
      default: false,
    },
    mobileBreakpoint: {
      type: Number,
      default: 600,
    },
    multiSort: Boolean,
    mustSort: Boolean,
    noDataText: {
      type: String,
      default: '$vuetify.noDataText',
    },
    noResultsText: {
      type: String,
      default: '$vuetify.dataIterator.noResultsText',
    },
    options: {
      type: Object,
      default: () => {
      },
    },
    page: {
      type: Number,
      default: 1,
    },
    search: {
      type: String,
      default: '',
    },
    selectableKey: {
      type: String,
      default: 'isSelectable',
    },
    serverItemsLength: {
      type: Number,
      default: -1,
    },
    short: Boolean,
    showGroupBy: Boolean,
    showSelect: Boolean,
    singleExpand: Boolean,
    singleSelect: Boolean,
    sortBy: {
      type: [String, Array],
      default: () => [],
    },
    sortDesc: {
      type: [Boolean, Array],
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // TODO заменить на i18, когда завезем локали
      language: { pages: 'Стр:' },
    };
  },
  computed: {
    hasItemSlot() {
      return this.$scopedSlots.item;
    },
    classList() {
      return {
        'is-short': this.short,
        'is-tall': this.tall,
        'is-extra-tall': this.extraTall,
        'compact-cells': this.headers.length >= 5,
        'is-expandable': this.showExpand,
      };
    },
  },
  methods: {},
};
</script>

<style lang="scss">
@import "TTDataTable";

</style>
