import isChatCompleted from '@/plugins/vue-router/guards/isChatCompleted';
import { hasToken } from '@/plugins/vue-router/guards/hasToken';
import hasLevel from '@/plugins/vue-router/guards/hasLevel';
import isTutorMiddleware from '@/plugins/vue-router/guards/isTutorMiddleware';
import accessToGoalsMiddleware from '@/plugins/vue-router/guards/accessToGoalsMiddleware';

import * as Names from './routeNames';

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName "[request]" */ '@/layouts/DefaultLayout.vue'),
    children: [
      {
        path: Names.R_APP_SIGNIN,
        name: Names.R_APP_SIGNIN,
        components: {
          default: () => import(/* webpackChunkName: "[request]" */ '@/views/signin/SignIn.vue'),
        },
      },
      {
        path: '',
        redirect: { name: Names.R_APP },
      },
      {
        path: 'errors/401',
        name: Names.R_ERROR_401,
        component: () => import(/* webpackChunkName "[request]" */ '@/views/errors/Error401.vue'),
      },
      {
        path: 'errors/403',
        name: Names.R_ERROR_403,
        component: () => import(/* webpackChunkName "[request]" */ '@/views/errors/Error403.vue'),
      },
      {
        path: 'errors/404',
        name: Names.R_ERROR_404,
        component: () => import(/* webpackChunkName "[request]" */ '@/views/errors/Error404.vue'),
      },
      {
        path: 'errors/500',
        name: Names.R_ERROR_500,
        component: () => import(/* webpackChunkName "[request]" */ '@/views/errors/Error500.vue'),
      },
    ],
  },

  {
    path: '/app',
    name: Names.R_APP,
    props: true,
    component: () => import(/* webpackChunkName "[request]" */ '@/layouts/app/DynamicLayout.vue'),
    redirect: { name: Names.R_APP_LEVELS },
    meta: {
      middleware: [
        hasToken,
        isChatCompleted,
      ],
    },
    children: [
      {
        path: Names.R_APP_LEVELS,
        name: Names.R_APP_LEVELS,
        components: {
          // nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNavigationPanel.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/levels/LevelsView.vue'),
          'aside-right': () => import(/* webpackChunkName "[request]" */ '@/components/track/TrackAsideRight.vue'),
        },
      },
      {
        path: Names.R_APP_TRACKS,
        name: Names.R_APP_TRACKS,
        components: {
          // nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNavigationPanel.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/tracks/TracksView.vue'),
        },
      },
      {
        path: 'tracks/:id',
        name: Names.R_APP_TRACK_VIEW,
        components: {
          // nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'tt-view-left': () => import(/* webpackChunkName "[request]" */ '@/components/shared/BackwardButton.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNavigationPanel.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/tracks/TrackView.vue'),
          'aside-right': () => import(/* webpackChunkName "[request]" */ '@/components/track/TrackAsideRight.vue'),
        },
      },
      {
        path: `${Names.R_APP_LEVELS}/:levelId/:taskId?`,
        name: Names.R_APP_LEVEL_VIEW,
        props: {
          default: true,
          'aside-right': true,
        },
        components: {
          // nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNavigationPanel.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/levels/LevelView.vue'),
          'aside-right': () => import(/* webpackChunkName "[request]" */ '@/components/task/TaskAsideRight.vue'),
        },
        meta: {
          middleware: [
            hasLevel,
          ],
        },
      },
      {
        path: Names.R_APP_GOALS,
        name: Names.R_APP_GOALS,
        components: {
          // nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'tt-view-left': () => import(/* webpackChunkName "[request]" */ '@/components/shared/BackwardButton.vue'),
          'tt-view-right': () => import(/* webpackChunkName "[request]" */ '@/components/shared/GoalFormButton.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNavigationPanel.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/goals/GoalsView.vue'),
        },
        meta: {
          usePortal: false,
          middleware: [
            accessToGoalsMiddleware,
          ],
        },
      },
      {
        path: `${Names.R_APP_GOALS}/:goalId`,
        name: Names.R_APP_GOAL_VIEW,
        props: {
          default: true,
          'aside-right': true,
        },
        components: {
          // nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNavigationPanel.vue'),
          'tt-view-left': () => import(/* webpackChunkName "[request]" */ '@/components/shared/BackwardButton.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/goals/GoalView.vue'),
          'aside-right': () => import(/* webpackChunkName "[request]" */ '@/components/goals/GoalAsideRight.vue'),
        },
        meta: {
          middleware: [
            accessToGoalsMiddleware,
          ],
        },
      },
      {
        path: Names.R_APP_HANDBOOK,
        name: Names.R_APP_HANDBOOK,
        components: {
          // nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNavigationPanel.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/handbook/HandbookView.vue'),
        },
      },
      {
        path: `${Names.R_APP_EVENTS}/:eventId`,
        name: Names.R_APP_EVENTS,
        components: {
          // nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNavigationPanel.vue'),
          'tt-view-left': () => import(/* webpackChunkName "[request]" */ '@/components/shared/BackwardButton.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/events/EventView.vue'),
          'aside-right': () => import(/* webpackChunkName "[request]" */ '@/components/events/EventAsideRight.vue'),
        },
      },
      {
        path: `${Names.R_APP_PROFILE}/:userId?`,
        name: Names.R_APP_PROFILE,
        props: {
          default: true,
        },
        components: {
          // nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNavigationPanel.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/profiles/ProfileView.vue'),
        },
      },
      {
        path: `${Names.R_APP_TRAINEE_PROFILE}/:userId?`,
        name: Names.R_APP_TRAINEE_PROFILE,
        props: {
          default: true,
        },
        meta: {
          middleware: [
            isTutorMiddleware,
          ],
        },
        components: {
          // nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'tt-view-left': () => import(/* webpackChunkName "[request]" */ '@/components/shared/BackwardButton.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNavigationPanel.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/trainees/id.vue'),
        },
      },
      {
        path: `:type/:userId/${Names.R_APP_GOAL_VIEW}/:goalId`,
        name: Names.R_APP_TRAINEE_GOAL_VIEW,
        props: {
          default: true,
          'aside-right': true,
        },
        meta: {
          middleware: [
            isTutorMiddleware,
          ],
        },
        components: {
          // nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNavigationPanel.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/goals/GoalView.vue'),
          'aside-right': () => import(/* webpackChunkName "[request]" */ '@/components/goals/GoalAsideRight.vue'),
        },
      },
      {
        path: Names.R_APP_CHAT,
        name: Names.R_APP_CHAT,
        components: {
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNavigationPanel.vue'),
          // nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/chats/ChatView.vue'),
        },
      },
      {
        path: Names.R_APP_TRAINEES,
        name: Names.R_APP_TRAINEES,
        meta: {
          middleware: [
            isTutorMiddleware,
          ],
        },
        components: {
          // nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNavigationPanel.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/trainees/index.vue'),
        },
      },
    ],
  },

  {
    path: '*',
    redirect: { name: Names.R_ERROR_404 },
  },
];
export default routes;
