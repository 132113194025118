export const toggleSnack = 'toggleSnack';
export const toggleLoading = 'toggleLoading';
export const toggleNavDrawer = 'toggleNavDrawer';

export const SET_SETTINGS = 'setSettings';

export const SET_ACTIVE_HAPPY_STATE = 'setActiveHappyState';
export const SET_HAPPY_STATES = 'setHappyStates';

export const SET_LEVEL_LIST = 'setLevelList';
export const MERGE_LEVELS = 'mergeLevels';

export const SKIP_ITEM = 'skipItem';
export const SET_ITEM_SKIPPED = 'setItemSkipped';

export const SET_HANDBOOK_EVENTS = 'setHandbookEvents';
export const SET_HANDBOOK = 'setHandbook';
export const SET_PURPOSE_LIST = 'setPurposeList';

export const SET_GOALS = 'setGoals';
export const SET_USER_GOALS = 'setUserGoals';
export const SET_GOALS_LOADIND = 'setGoalsLoadind';

export const SET_STAFF = 'setStaff';
export const SET_STAFF_LOADING = 'setStaffLoading';
export const SET_STAFF_PROPERTY = 'setStaffProperty';
