import { GET_STAFF_ACTION } from '@/plugins/vuex/actionTypes';
import { SET_STAFF, SET_STAFF_LOADING, SET_STAFF_PROPERTY } from '@/plugins/vuex/mutationTypes';
import { staffStatGet } from '@/services/api/snami';

const goalModule = {
  namespaced: true,
  state() {
    return {
      loading: false,
      staff: {},
    };
  },
  actions: {
    async [GET_STAFF_ACTION]({ commit, getters }) {
      if (!getters.loading) {
        commit(SET_STAFF_LOADING, true);
        const { data } = await staffStatGet({ rateCount: 10 });
        commit(SET_STAFF, data);
        commit(SET_STAFF_LOADING, false);
      }
    },
  },
  mutations: {
    [SET_STAFF](state, payload) {
      state.staff = payload;
    },
    [SET_STAFF_LOADING](state, payload) {
      state.loading = payload;
    },
    [SET_STAFF_PROPERTY](state, obj = {}) {
      Object.keys(obj).forEach((k) => {
        state.staff[k] = obj[k];
      });
    },
  },
  getters: {
    loading: (state) => state.loading,
    staff: (state) => state.staff,
    isRequestEmail: (state) => !!state.staff?.requestEmail,
    isEmptyEmail: (state) => !state.staff?.email || !state.staff?.isEmailConfirmed,
  },
};

export default goalModule;
