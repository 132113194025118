import Vue from 'vue';
import {
  i18n, router, store, vuetify, di as GLFeaturePlugin,
} from '@/plugins';
import App from '@/App.vue';

import * as snamiApi from '@/services/api/snami';
import accessToken from '@/services/cookies/accessToken';
import { INIT_APPLICATION_ACTION } from '@/plugins/vuex/actionTypes';

import AppNavbar from '@widgets/AppNavbar.vue';
import TTAvatar from '@uikit/components/TTAvatar/TTAvatar.vue';
import TTAlert from '@uikit/components/TTAlert.vue';
import TTAppBar from '@uikit/components/TTAppBar.vue';
import TTAutoComplete from '@uikit/components/TTAutocomplete/TTAutocomplete.vue';
import TTBtn from '@uikit/components/TTBtn/TTBtn.vue';
import TTChip from '@uikit/components/TTChip/TTChip.vue';
import TTChipGroup from '@uikit/components/TTChipGroup/TTChipGroup.vue';
import TTDataTable from '@uikit/components/TTDataTable/TTDataTable.vue';
import TTModuleMenu from '@uikit/components/TTModuleMenu/TTModuleMenu.vue';
import TTNavigationDrawer from '@uikit/components/TTNavigationDrawer.vue';
import TTSelect from '@uikit/components/TTTSelect/TTSelect.vue';
import TTSnackbar from '@uikit/components/TTSnackbar.vue';
import TTTextField from '@uikit/components/TTTextField/TTTextField.vue';
import TTToolBar from '@uikit/components/TTToolbar.vue';
import TTToolBarTitle from '@uikit/components/TTToolbarTitle.vue';
import TTView from '@views/TTView.vue';
import TTTextarea from '@uikit/components/TTTextarea/TTTextarea.vue';
import TTTooltip from '@uikit/components/TTTooltip/TTTooltip.vue';
// @ts-ignore
import TTCheckbox from '@uikit/components/TTCheckbox/TTCheckbox';
import TTBreadcrumbs from '@uikit/components/TTBreadcrumbs/TTBreadcrumbs.vue';
// @ts-ignore
import TTRadio from '@uikit/components/TTRadio/TTRadio';
// @ts-ignore
import TTNotify from '@uikit/components/TTNotify/TTNotify';

Vue.component('TTRadio', TTRadio);
Vue.component(TTAlert.name, TTAlert);
Vue.component('TTAvatar', TTAvatar);
Vue.component(TTAppBar.name, TTAppBar);
Vue.component(TTAutoComplete.name, TTAutoComplete);
Vue.component(TTBtn.name, TTBtn);
Vue.component('TTChip', TTChip);
Vue.component('TTChipGroup', TTChipGroup);
Vue.component(TTDataTable.name, TTDataTable);
Vue.component(TTModuleMenu.name, TTModuleMenu);
Vue.component(TTNavigationDrawer.name, TTNavigationDrawer);
Vue.component(TTSelect.name, TTSelect);
Vue.component(TTSnackbar.name, TTSnackbar);
Vue.component(TTTextField.name, TTTextField);
Vue.component(TTToolBar.name, TTToolBar);
Vue.component(TTToolBarTitle.name, TTToolBarTitle);
Vue.component(TTView.name, TTView);
Vue.component(TTTextarea.name, TTTextarea);
Vue.component(TTTooltip.name, TTTooltip);
Vue.component('TTCheckbox', TTCheckbox);
Vue.component('TTBreadcrumbs', TTBreadcrumbs);
Vue.component('AppNavbar', AppNavbar);
Vue.component('TTNotify', TTNotify);

Vue.config.productionTip = false;

(async () => {
  Vue.use(await GLFeaturePlugin);
  try {
    if (accessToken.get()) {
      // TODO Вынести все проверки авторизации и загрузку данных пользователя в middleware
      // hot-fix для авторизации
      // @ts-ignore
      const { data: configData } = await snamiApi.startConfigGet();
      const { data: levels } = await snamiApi.levelListV2Get();
      const bonusPhrases = configData.config?.phrase?.bonus;
      if (bonusPhrases) {
        const bonusPhrasesFilled = Object.values(bonusPhrases).every((item) => !!item);
        if (bonusPhrasesFilled) {
          i18n.mergeLocaleMessage(
            'ru',
            {
              // eslint-disable-next-line max-len,vue/max-len
              server_score_name: `${bonusPhrases.plural} | ${bonusPhrases.one} | ${bonusPhrases.two} | ${bonusPhrases.many}`,
            },
          );
        }
      }
      store.dispatch(INIT_APPLICATION_ACTION, { settings: configData, levels });
    }
  } catch (err) {
    // no-error
  } finally {
    new Vue({
      name: 'TalentTech',
      i18n,
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  }
})();
