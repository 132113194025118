// @ts-nocheck
// Styles
import Vue from 'vue';
import './TTCheckbox.scss';

import { VCheckbox, VIcon } from 'vuetify/lib';

const base = Vue.extend({ name: 'TTCheckbox', mixins: [VCheckbox] });
interface options extends InstanceType<typeof base> {}

// TODO: fix ts
// TODO: тесты!
export default base.extend<options>().extend({
  props: {
    dataTest: {
      type: String,
      default: 'tt-checkbox',
    },
    dataTestLabel: {
      type: String,
      default: '',
    },
    dataTestValue: {
      type: String,
      default: '',
    },
    hideDetails: {
      type: [Boolean, String],
      default: true,
    },
  },

  computed: {
    computedLabelColor() {
      if (this.isDisabled) return 'tt-light-mono-46';
      if (this.isDark || this.appIsDark) return 'tt-light-mono-0';
      return 'tt-light-mono-100';
    },
    computedIconColor(): string | undefined {
      if (this.isDisabled) return 'tt-light-mono-16';
      if (this.color) return this.color;
      if (this.isDark || this.appIsDark) return 'tt-light-mono-0';
      return 'tt-light-blue';
    },
  },

  mounted() {
    // HACK: VCheckbox вставляется в слот VInput, от сюда нет другого способа установить
    // атрибуты рут элементу. Поэтому ждем маунта и устанавливаем атрибуты.
    // Смотри дополнительно TTRadio.
    this.$el.setAttribute('data-test', this.dataTest);
    this.$el.setAttribute('data-test-label', this.dataTestLabel);
    this.$el.setAttribute('data-test-value', this.dataTestValue);
  },

  methods: {
    genLabel() {
      if (!this.hasLabel) return null;

      return this.$createElement('span', this.setTextColor(this.computedLabelColor, {
        slot: 'label',
        staticClass: 'checkbox-label tt-text-body-2',
      }), this.$slots.label || this.label);
    },
    genCheckbox() {
      return this.$createElement('div', {
        staticClass: 'tt-input v-input--selection-controls__input',
        class: 'align-self-baseline',
        attrs: {
          style: 'width: 16px; height: 16px;',
          // NOTE: добавляет атрибуты только на "чекбокс" без лейбла и рута.
          // Чтобы добавить к руту есть проблемы, т.к. это все наследуется от VInput, а сам VCheckbox
          // всего лишь слот этого VInput. Смотри "HACK" в mounted.
          // 'data-test': this.dataTest,
          // 'data-test-label': this.dataTestLabel,
          // 'data-test-value': this.dataTestValue,
        },
      }, [
        this.$createElement(VIcon, this.setTextColor(this.computedIconColor, {
          props: {
            size: 16,
          },
        }), this.computedIcon),
        this.genInput('checkbox', {
          ...this.attrs$,
          'aria-checked': this.inputIndeterminate
            ? 'mixed'
            : this.isActive.toString(),
        }),
      ]);
    },
  },
});
