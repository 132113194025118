import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/plugins/vuex';

import createRouter from '@/plugins/vue-router/router';
import { middlewarePipeline } from '@/plugins/vue-router/guards/middleware/middlewarePipeline';
import routes from '@/plugins/vue-router/routes';

Vue.use(VueRouter);

const { BASE_URL: baseUrl } = process.env;
const router = createRouter({
  baseUrl,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => middlewarePipeline({ to, next, store }));

export default router;
