/*
 * гвард должен возвращать false
 * guard для проверки существования уровня и его состояние не закрыт
 */
import { R_APP } from '@/plugins/vue-router/routeNames';
import { CLOSED } from '@/helpers/constants';

const hasLevel = ({ to, store }) => {
  const level = store.getters['levels/levelById'](Number(to.params.levelId));
  if (!level || level.state === CLOSED) {
    return { name: R_APP };
  }
  return false;
};

export default hasLevel;
