export const R_APP = 'home';
export const R_ERROR_401 = 'error.401';
export const R_ERROR_403 = 'error.403';
export const R_ERROR_404 = 'error.404';
export const R_ERROR_500 = 'error.500';

export const R_APP_LEVELS = 'levels';
export const R_APP_LEVEL_VIEW = 'levels.view';

export const R_APP_GOALS = 'goals';
export const R_APP_GOAL_VIEW = 'goals.view';

export const R_APP_TRAINEES = 'trainees';
export const R_APP_TRAINEE_GOAL_VIEW = 'goals/trainees';
export const R_APP_TRAINEE_PROFILE = 'profile/trainees';

export const R_APP_EVENTS = 'events';

export const R_APP_HANDBOOK = 'handbook';

export const R_APP_PROFILE = 'profile';

export const R_APP_CHAT = 'chat';

export const R_APP_LOGIN = 'login';
export const R_APP_SIGNIN = 'signin';

export const R_APP_TRACKS = 'tracks';
export const R_APP_TRACK_VIEW = 'tracks.view';
