<template>
  <TTAppBar
    app
    clipped-left
    :clipped-right="clippedRight"
  >
    <TTToolbarTitle class="mr-12">
      <slot name="brand">
        <TTBtn
          text
          color="tt-light-mono-100"
          :depressed="false"
          :href="brandUrl"
          to="/"
          class="pa-0"
        >
          <div class="d-flex align-center">
            <svg
              class="mr-3"
              width="30"
              height="10"
              viewBox="0 0 30 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="20.4085"
                cy="5.5915"
                r="3.5915"
                fill="#601EF3"
              />
              <circle
                cx="12.0008"
                cy="5.5915"
                r="3.5915"
                fill="#00A0F2"
              />
              <circle
                cx="3.5915"
                cy="5.5915"
                r="3.5915"
                fill="#FFB500"
              />
            </svg>

            <div class="tt-text-caption">
              TalentТech
            </div>
          </div>
        </TTBtn>
      </slot>
    </TTToolbarTitle>

    <div class="d-flex align-center">
      <TTBtn
        v-for="route in items"
        :key="route.text"
        text
        :depressed="false"
        color="tt-light-mono-100"
        :href="isExternalLink(route)"
        :to="route.to"
        :class="{ 'v-btn--active' : currentApp === route.href }"
        :data-test-value="route.testValue"
        :data-test-label="route.testLabel"
        height="46"
        class="tt-text-caption font-weight-regular px-0 mr-6 custom-item"
        @click="$emit('click-module-btn', route)"
      >
        {{ route.text }}
      </TTBtn>
    </div>

    <VSpacer />

    <slot name="company">
      <div class="d-flex align-center">
        <svg
          class="mr-3"
          width="14"
          height="5"
          viewBox="0 0 14 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="11.3869"
            cy="2.78691"
            r="1.87382"
            fill="#601EF3"
          />
          <circle
            cx="7.00023"
            cy="2.78691"
            r="1.87382"
            fill="#00A0F2"
          />
          <circle
            cx="2.61296"
            cy="2.78691"
            r="1.87382"
            fill="#FFB500"
          />
        </svg>

        <div>
          <div class="tt-text-caption">
            ООО “Севергрупп-ТТ”
          </div>

          <div class="company-subtitle font-weight-normal tt-light-mono-46--text">
            Моя компания
          </div>
        </div>
      </div>
    </slot>
  </TTAppBar>
</template>

<script>
export default {
  name: 'AppNavbar',
  props: {
    currentApp: {
      type: String,
      default: process.env.VUE_APP_ARCHIVIST_ADDRESS,
    },
    items: {
      type: Array,
      default: () => [
        { text: 'Цифровой профиль', href: process.env.VUE_APP_ARCHIVIST_ADDRESS },
        { text: 'Цели', href: process.env.VUE_APP_GOALS_ADDRESS },
        { text: 'Обучение', href: process.env.VUE_APP_LEARNING_ADDRESS },
        { text: 'Опросы', href: process.env.VUE_APP_ENGAGEMENTADMIN_ADDRESS },
      ],
    },
    brandUrl: {
      type: String,
      default: null,
    },
    clippedRight: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isExternalLink(route) {
      return (!route.event && route.href) || undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.company-subtitle {
  font-size: 0.625rem;
}

.custom-item {
  min-width: auto !important;

  &.v-btn--active > &::after,
  &:hover > &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: map-get($tt-light-mono-100, 'base');
  }

  &::before,
  &:focus > &::before{
    opacity: 0 !important;
  }
}
</style>
